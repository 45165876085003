import { getIdentity, useIdentity } from 'shared/auth/AuthContext';
import { QuickFindModal } from '../components/QuickFind/QuickFindModal';
import Head from 'next/head';

export async function getServerSideProps(ctx) {
  const initialIdentity = await getIdentity(ctx.req);
  return {
    props: { initialIdentity },
  };
}

export default function Page() {
  const session = useIdentity();

  if (session?.role !== 'ADMIN') {
    return <p>Access Denied</p>;
  }

  return (
    <>
      <Head>
        <title>WW Dashboard</title>
      </Head>
      {/* <DashboardPage /> */}
      {/* <QuickFindModal /> */}
    </>
  );
}
